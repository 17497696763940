import { IWebsite } from '../interfaces/huWebsite';

const websites: IWebsite[] = [
  {
    code: 'AM',
    hostnames: ['altomincio.huopenair.com'],
    title: 'Altomincio Village',
    properties: ['AM'],
    showAvailabilityCategory: true,
    languages: ['en', 'it', 'de', 'nl', 'fr', 'pl'],
    contacts: {
      mail: 'booking@huopenair.com',
      tel: '+390550298080'
    },
    logo: {
      path: 'logo_HU.jpg',
      width: '70',
      height: '70',
      alt: ''
    }
  },
  {
    code: 'IP',
    hostnames: ['ipini.huopenair.com'],
    title: 'I Pini Village',
    properties: ['IP'],
    showAvailabilityCategory: true,
    languages: ['en', 'it', 'de', 'nl', 'fr', 'pl'],
    contacts: {
      mail: 'booking@huopenair.com',
      tel: '+390550298080'
    },
    logo: {
      path: 'logo_HU.jpg',
      width: '70',
      height: '70',
      alt: ''
    }
  },
  {
    code: 'BK',
    hostnames: ['birkelt.huopenair.com'],
    title: 'Birkelt Village',
    properties: ['BK'],
    showAvailabilityCategory: true,
    languages: ['en', 'it', 'de', 'nl', 'fr', 'pl'],
    contacts: {
      mail: 'booking@huopenair.com',
      tel: '+390550298080'
    },
    logo: {
      path: 'logo_HU.jpg',
      width: '70',
      height: '70',
      alt: ''
    }
  },
  {
    code: 'NG',
    hostnames: ['norcenni.huopenair.com', 'norcenni-staging.huopenair.com'],
    title: 'Norcenni Girasole',
    properties: ['NG'],
    showAvailabilityCategory: true,
    languages: ['en', 'it', 'de', 'nl', 'fr', 'pl'],
    contacts: {
      mail: 'booking@huopenair.com',
      tel: '+390550298080'
    },
    logo: {
      path: 'logo_HU.jpg',
      width: '70',
      height: '70',
      alt: ''
    }
  },
  {
    code: 'PA',
    hostnames: [
      'parkalbatros.huopenair.com',
      'parkalbatros-staging.huopenair.com'
    ],
    title: 'Park Albatros Village',
    properties: ['PA'],
    showAvailabilityCategory: true,
    languages: ['en', 'it', 'de', 'nl', 'fr', 'pl'],
    contacts: {
      mail: 'booking@huopenair.com',
      tel: '+390550298080'
    },
    logo: {
      path: 'logo_HU.jpg',
      width: '70',
      height: '70',
      alt: ''
    }
  },
  {
    code: 'MS',
    hostnames: ['montescudaio.huopenair.com'],
    title: 'Montescudaio Village',
    properties: ['MS'],
    showAvailabilityCategory: true,
    languages: ['en', 'it', 'de', 'nl', 'fr', 'pl'],
    contacts: {
      mail: 'booking@huopenair.com',
      tel: '+390550298080'
    },
    logo: {
      path: 'logo_HU.jpg',
      width: '70',
      height: '70',
      alt: ''
    }
  },
  {
    code: 'CE',
    hostnames: [
      'staging.firenzecertosacamping.com',
      'firenzecertosacamping.com',
      'firenzecertosa.huopenair.com'
    ],
    title: 'Firenze Certosa Camping',
    properties: ['CE'],
    showAvailabilityCategory: true,
    languages: ['en', 'it', 'de', 'nl', 'fr', 'pl'],
    contacts: {
      mail: 'booking@firenzecertosacamping.com',
      tel: '+390550299146'
    },
    logo: {
      path: 'logo_CE.svg',
      width: '200',
      height: '70',
      alt: 'Logo Certosa'
    }
  },
  {
    code: 'HU',
    hostnames: [
      'huopenair.com',
      'www.huopenair.com',
      'victorious-beach-0a321c803.3.azurestaticapps.net',
      'test.huopenair.com',
      'localhost'
    ],
    title: 'HuOpen Air',
    properties: ['AM', 'NG', 'PA', 'MS'],
    showAvailabilityCategory: true,
    languages: ['en', 'it', 'de', 'nl', 'fr', 'pl'],
    contacts: {
      mail: 'booking@huopenair.com',
      tel: '+390550298080'
    },
    logo: {
      path: 'logo_HU.jpg',
      width: '70',
      height: '70',
      alt: ''
    }
  },
  {
    code: 'VP',
    hostnames: [
      'palagina.it',
      'www.palagina.it',
      'test.palagina.it',
      'palagina.azurewebsites.net',
      '127.0.0.1',
    ],
    title: 'Villa Palagina',
    properties: ['LC', 'VP'],
    showAvailabilityCategory: false,
    languages: ['en', 'it', 'de', 'nl', 'fr'],
    contacts: {
      mail: 'info@palagina.it',
      tel: '+390559502029'
    },
    logo: {
      path: 'logo_VP.svg',
      width: '70',
      height: '70',
      alt: ''
    }
  },
  {
    code: 'EV',
    hostnames: ['humantravel.com'],
    title: 'Human Travel',
    properties: ['AM', 'NG', 'PA'],
    showAvailabilityCategory: false,
    languages: ['en', 'it', 'de', 'nl', 'fr', 'pl', 'hu', 'da'],
    contacts: {
      mail: 'booking@huopenair.com',
      tel: '+390550298080'
    },
    logo: {
      path: 'logo_HU.jpg',
      width: '70',
      height: '70',
      alt: ''
    }
  }
];

export default websites;
